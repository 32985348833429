// btn
.btn {
	box-shadow: $shadow;
	font-weight: 700;

	// btn-primary
	&.btn-primary {
		&:hover {}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {}
	}
}

// card-btn
.card-btn {
	@extend .link-arrow;
}

// btn-back
.btn-back {
	display: inline-flex;
	align-items: center;
	color: $green;
	font-size: 16px;
	font-weight: 700;
	text-decoration: none !important;

	&::before {
		content: "\f0a8";
		font-size: 18px;
		font-weight: 300;
		font-family: $font-awesome;
		line-height: 1;
		margin-right: 10px;
	}

	&:hover {
		color: $green-dark;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
