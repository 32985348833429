// footer
.footer {
	margin: 8vh 15px 0 15px;
	color: $white;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $white;
	}

	a {
		color: $white;

		&:hover {
			color: $yellow;
		}
	}

	.footer-address {
		.container-fluid {
			padding-top: 50px;

			@include media-breakpoint-up(xl) {
				padding-top: 150px;
			}

			background: $green-dark;
			border-radius: 20px 20px 0 0;

			.container-holder {
				@include media-breakpoint-up(xl) {
					max-width: 1290px;
					margin: 0 auto;
				}
			}
		}

		// footer-text
		.footer-text {
			h3 {
				margin-bottom: 1rem;
			}

			ul {
				margin: 0;
				padding: 0;
				list-style: none;

				li {

					// tel/mailto
					a {
						display: inline-flex;
						align-items: center;
						color: $yellow;
						font-size: 16px;
						font-weight: 700;
						text-decoration: none;

						&::after {
							font-family: $font-awesome;
							display: inline-block;
							margin-left: 10px;
							color: $white;
							font-size: 18px;
							font-weight: 300;
						}

						&[href^="tel:"] {
							&::after {
								content: "\f879";
							}
						}

						&[href^="mailto:"] {
							&::after {
								content: "\f0e0";
							}
						}

						&:hover {
							color: $white;
						}
					}
				}

			}
		}
	}

	// footer-logolink
	.footer-logolink {
		@include media-breakpoint-up(xl) {
			margin-left: auto;
		}

		.list {
			.list-item {
				+li {
					margin-left: 30px;
				}

				.link {
					font-size: 30px;

					@include media-breakpoint-up(xl) {
						font-size: 36px;
					}
				}
			}
		}
	}

	.footer-copyright {
		.container-fluid {
			padding-top: 6vh;
			padding-bottom: 30px;
			background: $green-dark;

			.container-holder {
				@include media-breakpoint-up(xl) {
					max-width: 1290px;
					margin: 0 auto;
				}
			}
		}

		// footer-link
		.footer-link {
			.list {
				@include media-breakpoint-down(sm) {
					flex-direction: column;
				}

				.list-item {
					font-size: 14px;
					font-weight: 700;

					+li {
						@include media-breakpoint-up(md) {
							margin-left: 30px;
						}
					}

					.link {
						color: $white;
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}
					}
				}

			}
		}
	}
}
