// header
.header {
	background: $green-light;

	.header-top {
		position: relative;
		z-index: 101; // above logo
	}

	[class^="container-"] {
		.container-holder {
			>.column {
				@extend .align-items-center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			@include media-breakpoint-down(lg) {
				display: flex !important;
			}

			align-items: center;
			padding: 0;
			color: $green-darker;
			font-size: 18px;
			font-weight: 700;
			line-height: 24px;
			transition: color 0.5s;

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}

			.navbar-toggler-icon {
				font-size: 24px;
			}

			.navbar-toggler-label {
				margin-left: 10px;
			}

			&:hover {
				color: $green;
			}
		}
	}

	// logo
	.logo {
		z-index: 100;
		width: 180px;
		margin: 0 auto -80px auto;

		@include media-breakpoint-up(xl) {
			width: 230px;
			margin-top: -40px;
			margin-bottom: -115px;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 3;

			.navbar-nav {
				>.nav-item {
					font-size: 18px;
					font-weight: 700;

					>.nav-link {
						display: block;
						color: $green-darker;

						&:hover {
							color: $green-dark;
						}
					}

					&.active {
						>.nav-link {
							color: $green;
						}
					}

					.dropdown-toggle {
						&::after {
							content: "\f107";
							font-family: $font-awesome;
							font-size: 20px;
							font-weight: 400;
							border: unset;
							vertical-align: unset;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 15px;
						padding: 0;
						border: none;
						background-color: transparent;

						.nav-item {
							font-size: 16px;
							font-weight: 700;
							line-height: normal;

							.nav-link {
								color: $green;

								&:hover {
									color: $green-dark;
								}
							}

							&.active {
								.nav-link {
									color: $green-dark;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				margin: 12px 0;

				>.nav-item {
					margin-right: 30px;
					font-size: 18px;
					font-weight: 700;

					>.nav-link {
						display: block;
						padding: 0 !important;
						color: $green-darker;
						line-height: 39px;

						&:hover {
							color: $green-dark;
						}
					}

					&.active {
						>.nav-link {
							color: $green;
						}
					}

					.dropdown-toggle {
						&::after {
							content: "\f107";
							font-family: $font-awesome;
							font-size: 20px;
							font-weight: 400;
							border: unset;
							vertical-align: unset;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						top: unset !important;
						left: unset !important;
						transform: unset !important;
						margin-top: 0;
						padding: 10px;
						border-radius: 0 10px 10px 10px;
						box-shadow: $shadow;
						border: none;

						&::before,
						&::after {
							display: none;
						}

						.nav-item {
							font-size: 16px;
							font-weight: 700;
							line-height: 1.2;

							.nav-link {
								color: $green;

								&:hover {
									color: $green-darker;
								}
							}

							&.active {
								.nav-link {
									color: $green-darker;
								}
							}
						}
					}
				}
			}

		}
	}

	// book-button
	div.book-button {
		margin-left: auto;

		ul {
			li {
				a {
					display: flex;
					align-items: center;
					height: 50px;
					padding: 0 15px;
					border-radius: 0 0 10px 10px;
					box-shadow: 3px 3px 15px rgba($black, 0.4);
					background: $green-dark;
					color: $yellow;
					font-size: 16px;
					font-weight: 700;
					line-height: 1;
					text-align: center;
					text-decoration: none;

					@include media-breakpoint-down(lg) {
						max-width: 100px;
					}

					@include media-breakpoint-up(xl) {
						padding: 0 40px;
						height: 64px;
						font-size: 18px;
					}

					&:hover {
						background: $yellow;
						color: $green-dark;
					}
				}
			}
		}
	}

	&.sticky {
		div.book-button {
			position: fixed;
			top: 0;
			right: 15px;

			@include media-breakpoint-up(xl) {
				right: 40px;
			}
		}
	}

}
