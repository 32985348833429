// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 75vh;
	min-height: 400px;
	max-height: 820px;
}

.eyecatcher {
	.item {
		border-radius: 20px;
		overflow: hidden;
	}
	.owl-dots {
		display: none;
	}
	.owl-carousel {
		.owl-nav {
			.owl-prev {
				&:hover {
					left: -3px;
					color: $white;
				}
			}
			.owl-next {
				&:hover {
					right: -3px;
					color: $white;
				}
			}
		}
	}
}
