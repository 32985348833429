// mini-sab
.mini-sab {
	@include media-breakpoint-down(lg) {
		margin-left: 15px;
		margin-right: 15px;
	}
	.container {
		position: relative;

		.container-holder {
			@extend .bg-dark;
			z-index: 100;
			position: relative;
			margin: -75px 0 0 0;
			padding-top: 30px;
			padding-bottom: 30px;
			border-radius: 20px;
			color: #fff;

			.column {
				max-width: 990px;
				margin: 0 auto;
				padding-left: 30px;
				padding-right: 30px;

				hr {
					border-color: $white;
				}

				h1, h2 {
					max-width: 640px;
					font-size: 30px;
					@include media-breakpoint-up(xl) {
						font-size: 40px;
					}
				}

				h5 {
					color: $yellow;
					font-weight: 700;
					font-family: $font-family-primary;
				}

				p {
					&:last-of-type {
						margin-bottom: 0;
					}
				}

				.link-arrow {
					margin-top: 10px;
					color: $yellow !important;
					font-size: 24px;
					@include media-breakpoint-up(xl) {
						font-size: 30px;
					}
					font-weight: 400;
					line-height: 1;
					font-family: $font-family-secondary;

					&::after {
						font-size: 20px;
						@include media-breakpoint-up(xl) {
							font-size: 24px;
						}
					}

					&:hover {
						color: $white !important;
					}
				}
			}
		}
	}
}
