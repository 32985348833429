// bodyx
& {
	@include media-breakpoint-up(xl) {
		padding-left: 25px;
		padding-right: 25px;
	}
}

// headings
h1 {
	font-size: 40px;
}
h2 {
	font-size: 36px;
}
h3 {
	font-size: 24px;
}
&.home {
	h2 {
		font-size: 40px;
	}
}

// containers
.container-fluid {
	max-width: 1750px;
}

.container-small {
	@include media-breakpoint-up(xl) {
		max-width: 960px;
	}
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// link-arrow
a.link-arrow {
	display: inline-flex;
	align-items: center;
	color: $green;
	font-size: 16px;
	font-weight: 700;
	text-decoration: none !important;

	&::after {
		content: "\f0a9";
		font-size: 18px;
		font-weight: 300;
		font-family: $font-awesome;
		line-height: 1;
		margin-left: 10px;
		transition: margin 0.5s;
	}

	&:hover {
		color: $green-dark;

		&::after {
			margin-left: 15px;
		}
	}
}

// card
.default-card {
	border-radius: 10px;
	border: none;
	box-shadow: $shadow;

	.card-image {
		border-radius: 10px;

		&::after {
			content: "\f0a9";
			position: absolute;
			left: 20px;
			bottom: 20px;
			color: $white;
			font-size: 36px;
			font-weight: 300;
			line-height: 1;
			font-family: $font-awesome;
		}

	}

	.card-body {
		padding: 20px 20px 30px 20px;

		.card-caption {

			.card-title,
			.card-title-link {
				color: $green-darker;
			}

			.card-description {
				.list {
					margin-top: 1.5rem;

					.list-item {
						margin-bottom: 0.5rem;
						padding-left: 35px;

						.icon {
							i {
								font-size: 18px;
								font-weight: 300;
							}
						}

						.label {
							font-size: 14px;
							font-weight: 700;
						}
					}
				}
			}
		}
	}
}

// modal
&.modal-open {
	overflow: visible;
}
.modal-backdrop {
	display: none;
}
.notice-modal {
	top: auto;
	left: auto;
	right: 0;
	bottom: 0;
	width: auto;
	height: auto;
	padding-right: 0 !important;

	.modal-dialog {
		margin: 15px !important;
		max-width: 360px;

		.modal-content {
			padding-right: 30px;
			border: none;
			border-radius: 10px;
			box-shadow: $shadow;
			background: $white;

			.modal-header {
				border-bottom: 0;
				padding-bottom: 0;

				.close {
					position: absolute;
					top: 0;
					right: 0;
					display: block;
					width: 18px;
					height: 18px;
					margin: 6px;
					padding: 0;
					background: $yellow;
					border-radius: 100%;
					color: $white;
					font-size: 18px;
					font-family: sans-serif;
					opacity: 1;
					text-shadow: none;
					transition: 0.5s;

					&:hover {
						background: $white;
						color: $yellow;
					}
				}
			}

			.modal-body {
				a {
					@extend .link-arrow;

				}

				p:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}
}

// gallery
.gallery,
.gallery-horizontal {
	.gallery-item {
		.gallery-link {
			border-radius: 10px;
		}
	}
}
