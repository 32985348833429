// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$green: #459b28;
$green-light: #f0f2e7;
$green-dark: #216928;
$green-darker: #163d1b;
$brown-light: #d6d6c8;
$yellow: #ffc513;
$white: #fff;
$black: #000;

// theme-colors
$primary: $green-darker;
$secondary: $green-dark;

// body
$body-bg: $green-light;
$body-color: $black;

// links
$link-color: $primary;
$link-decoration: underline;

// typography
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Roboto:wght@100;300;400;500;700;900&display=swap');
$font-family-primary: 'Roboto', sans-serif;
$font-family-secondary: 'Abril Fatface', cursive;

$font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$h1-font-size: $font-size-base * 2.5; // 40px
$h2-font-size: $font-size-base * 2.25; // 36px
$h3-font-size: $font-size-base * 1.5; // 24px
$h4-font-size: $font-size-base * 1.25; // 20px
$h5-font-size: $font-size-base * 1.125; // 18px
$h6-font-size: $font-size-base; // 16px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 400;
$headings-color: $green-darker;

// contextual
$light: $green-light;
$dark: $green-dark;
$text-light: $white;
$text-dark: $black;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

// grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;
*/
