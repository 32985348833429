// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

&.home {
	.lead-section {
		margin: 4vh 0;
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;

	// intro/outro
	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	// boxed
	.boxed {
		.wysiwyg {
			padding: 15px;

			@include media-breakpoint-up(xl) {
				padding: 30px 40px;
			}

			box-shadow: $shadow;
			background: $white;
			border-radius: 10px;

			ul {
				display: flex;
				flex-direction: column;

				@include media-breakpoint-up(lg) {
					flex-flow: row wrap;
				}

				margin-bottom: 0;

				li {
					@include media-breakpoint-up(lg) {
						width: 50%;
					}

					margin: 8px 0;
					color: $green-dark;
					font-weight: 700;
				}
			}
		}
	}

}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		width: auto;

		.owl-stage-outer {
			margin-left: -15px;
			margin-right: -15px;
			padding: 15px;
		}

		.card {
			@extend .default-card;
		}
	}

	// link
	div.link {
		margin-top: 15px;

		.link {
			display: block;
			@extend .btn;
			@extend .btn-secondary;
			border-radius: 10px;
			padding: 15px;

			@include media-breakpoint-up(xl) {
				padding: 30px;
			}

			font-size: 20px;
			font-weight: 400 !important;
			font-family: $font-family-secondary;

			&::after {
				content: "\f0a9";
				font-size: 20px;
				font-weight: 300;
				font-family: $font-awesome;
				margin-left: 15px;
				transition: margin 0.5s;
			}

			&:hover {
				&::after {
					margin-left: 20px;
				}
			}
		}
	}
}

// photos-section
// =========================================================================
.photos-section {
	margin: 6vh 0;
	padding: 15px 0;
	background: $brown-light;

	@include media-breakpoint-up(xl) {
		margin-left: -25px;
		margin-right: -25px;
		padding: 40px 25px;
	}

	.collection {
		.card {
			border: none;
			border-radius: 10px;

			.card-img-overlay {
				display: none;
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
	margin: 6vh 0 140px 0;

	// owl-carousel
	.owl-carousel {
		height: 75vh;
		min-height: 320px;
		max-height: 800px;

		.owl-stage-outer {
			padding-bottom: 140px;
		}

		.item {
			height: 75vh !important; // overide
			min-height: 320px;
			max-height: 800px;
			padding: unset;
			border-radius: 20px;
			align-items: flex-end;

			.owl-caption {
				min-height: 140px;
				margin-bottom: -60px;
				padding: 25px 30px;

				@include media-breakpoint-up(xl) {
					padding: 30px 75px;
				}

				background: $green-dark;
				border-radius: 20px;
				text-shadow: none;
				text-align: left;

				.owl-title {
					@include media-breakpoint-up(xl) {
						padding-right: 25%;
					}

					font-size: 30px;
				}

				.owl-description {
					p {
						margin-bottom: 0;
					}
				}
			}
		}

		.owl-nav {
			display: none;
		}

		.owl-dots {
			@include media-breakpoint-down(lg) {
				top: 100%;
				right: 30px;
				bottom: auto;
			}

			@include media-breakpoint-up(xl) {
				max-width: 1000px;
				margin: 0 auto;
			}

			justify-content: flex-end;

			.owl-dot {
				span {
					width: 18px;
					height: 18px;
					border-width: 1px;
				}
			}
		}
	}

}

// featured-section
// =========================================================================
.featured-section {
	margin: 6vh 15px;

	.container,
	.container-fluid {
		background: $brown-light;
		border-radius: 20px;
		padding-top: 20px;
		padding-bottom: 20px;

		@include media-breakpoint-up(xl) {
			padding: 40px;
		}
	}

	.container-holder {
		@include media-breakpoint-up(xl) {
			max-width: 1290px;
			margin: 0 auto;
			align-items: center;
		}

		img {
			border-radius: 10px;
		}

		p:last-of-type {
			margin-bottom: 0;
		}
	}

}

// map-section
// =========================================================================
.map-section {
	margin: 6vh 0;
}

// faq-section
// =========================================================================
.faq-section {
	margin: 6vh 0;

	.faq-overview {
		margin-top: 30px;

		.faq-category {
			.faq-items {
				margin-bottom: 0;
			}
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	.collection {
		.card {
			@extend .default-card;
		}
	}

}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 6vh 0;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}
