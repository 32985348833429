.faq-overview {

	.faq-category {
		.faq-title {
			pointer-events: none;

			.faq-icon {
				display: none;
			}
		}

		.faq-items {
			display: block !important;
			margin-bottom: 30px;

			.faq-item {
				margin-bottom: 5px;
				padding: 10px 15px;
				background: $white;
				box-shadow: $shadow;
				border-radius: 10px;

				@include media-breakpoint-up(xl) {
					padding: 20px 40px;
				}

				.faq-question {
					display: flex;
					align-items: center;

					h3 {
						margin-bottom: 0;
						transition: color 0.5s;
					}

					&:hover {
						cursor: pointer;

						.faq-category {
							h3 {
								color: $green;
							}
						}
					}

					.faq-icon {
						margin-left: auto;
						line-height: 1;

						i {
							&::before {
								content: "\f055";
								color: $green;
								font-size: 18px;
								font-weight: 300;
							}
						}
					}
				}

				&.active {
					.faq-question {
						.faq-icon {
							i {
								&::before {
									content: "\f056";
									color: $brown-light;
								}
							}
						}
					}
				}

				.faq-answer {
					display: none;
					margin-top: 15px;

					p {
						&:last-of-type {
							margin-bottom: 0;
						}
					}

				}
			}
		}
	}

	&.js-single-category {
		.faq-items {
			display: block;
		}
	}
}
